<template>
    <div class="wrapper">
        <Slider></Slider>

        <!-- Table for winner data -->
        <div v-if="winner && winner.length">
            <table class="table table-striped table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th># Amount</th>
                        <th>Jackpot ID</th>
                        <th>Player ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(player, index) in winner" :key="index">
                        <td>{{ player.amount }}</td>
                        <td>{{ player.jackpotTierId }}</td>
                        <td>{{ player.playerId }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- No data available image, centered -->
        <div v-else class="no-data-container">
            <img src="/menu/nodata.png" alt="No data available" class="no-data-image" />
        </div>
    </div>
</template>

<script>
import axios from "@/services/api";
const Slider = () => import('../Slider.vue');

export default {
    data() {
        return {
            winner: []
        };
    },
    components: {
        Slider,
    },
    mounted() {
        this.fetchLeaderboard();
    },
    methods: {
        fetchLeaderboard() {
            const url = `${process.env.VUE_APP_BASE_WINNER_URL}/winners`;
            axios
                .get(url)
                .then(response => {
                    if (response.data && response.data.winners) {
                        this.winner = response.data.winners;
                    } else {
                        this.winner = [];
                    }
                })
                .catch(error => {
                    console.error("Error fetching winner:", error);
                    this.winner = [];
                });
        }
    }
};
</script>

<style scoped>
.table-striped tbody tr:nth-of-type(even) {
    color: #343a40;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

/* Flexbox styling to center the image */
.no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    /* Full view height to vertically center */
}

.no-data-image {
    max-width: 400px;
    height: auto;
}
</style>
